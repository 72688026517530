/* 
 * IMPORTANT NOTE: DO NOT Edit this file. Best to write own code in css/theme.css file.
 * It will helps you to easy update of the template.
 **/

/*
----------------------
## Table Contents ##
* 01. FONTS
* 02. VENDORS (Bootstraps, NioIcon, Select2 etc)
* 03. LAYOUTS
* 04. UTILITES
* 05. COMPONENTS
* 06. INDIVIDUL CONCEPTS
* 07. PREVIEWS
---------------------- **/

////////////////////////// DO NOT REMOVE BELOW ///////////////////////

// SET RTL OPTION
$rtl: false;

// Included Global Variables and Functions
@import "dashlite_variables";

// Included Fonts
/** 01. FONTS */
@import "core/fonts/roboto";
@import "core/fonts/nunito";

// Included Bootstarp
@import "extend/bootstrap/variables";
@import "extend/bootstrap/bootstrap";
@import "extend/bootstrap/extend";

// Include Other Vendors
@import "vendors/bundle";

// Include Core Dashlite Style
@import "core/style";

////////////////////////// DO NOT REMOVE ABOVE ///////////////////////

////// GLOBAL INCLUDED
@import "global/style";

//////  INDIVIDUL CONCEPTS 
@import "invest/style";

//////  APPLICATONS - Common Uses
@import "apps/asterisk"; 
@import "apps/reply-comment"; 
@import "apps/attach"; 

//////  Apps
@import "apps/messages";  		// - Support Messages
@import "apps/inbox";  			// - Inbox/ Mail App
@import "apps/file-manager";  	// - File Manager
@import "apps/chats";  			// - Chats

///////  PREVIEW PURPOSE 
@import "core/preview"; 		// Can be remove on real projoect

///////  UI/MODE OVERRIDE
@import "core/mode-override";

///////  DARK MODE SKIN
@import "core/dark-skin";

/*! END @iO */
////////////////////////// END STYLESHEET ////////////////////////////

// Add theme rewrites
.border-primary {
  border-radius: 0 !important;
  border-color: #dbdfea !important;
  background-color: white !important;
}
.text-ssbazul {
  color: #18263F !important;
}

.text-ssbnaranja {
  color: #d56513 !important;
}
.border-secondary {
  @extend .border-primary;
  &.card {
    *.form-group {
      text-align: left !important;
    }
    *.text-primary {
      color: black !important;
    }
  }
}

iframe {
  border: none;
}

.iframe-card {
  height: inherit;
  display: flex;
  flex-direction: column;
  & > .card-inner {
    padding: 0;
    flex-grow: 1;
    & > iframe {
      width: 100%;
    }
  }
}
